import Swal, {SweetAlertIcon, SweetAlertOptions} from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

const buildOptions = (item: HTMLElement, defaults: SweetAlertOptions): SweetAlertOptions => {
    const icon = item.getAttribute('data-sweet-icon') || defaults.icon;
    return {
        ...defaults,
        title: item.getAttribute('data-sweet-title') || defaults.title,
        text: item.getAttribute('data-sweet-text') || defaults.text,
        confirmButtonText: item.getAttribute('data-sweet-confirm-button') || defaults.confirmButtonText,
        showConfirmButton: item.hasAttribute('data-sweet-confirm-button'),
        confirmButtonColor: item.getAttribute('data-sweet-confirm-color') || defaults.confirmButtonColor,
        cancelButtonText: item.getAttribute('data-sweet-cancel-button') || defaults.cancelButtonText,
        showCancelButton: item.hasAttribute('data-sweet-cancel-button'),
        showCloseButton: item.hasAttribute('data-sweet-close-button'),
        icon: icon as SweetAlertIcon,
        focusConfirm: false,
        focusCancel: false,
    }
}

export const initSweetAlert2 = (): void => {
    const defaultOptions: SweetAlertOptions = {
        title: 'Titre',
        text: 'Texte',
        icon: undefined, // default icon type if needed
    };

    document.querySelectorAll<HTMLElement>('[data-sweet-notification]').forEach((item) => {
        const options = buildOptions(item, defaultOptions);

        item.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            Swal.fire(options).then((result) => {
                if (result.isConfirmed && item.closest('form')) {
                    item.closest('form')!.submit();
                }
            });
        });
    });
};


