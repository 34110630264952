// @ts-ignore
import.meta.glob(
    '../static/**'
);

import './bootstrap';
import 'flowbite';
import Cookies from 'js-cookie';

import {getNumberLicences, initNumberLicences, setNumberLicences} from "./components/NumberLicences";
import { initSubscriptionRights } from "./components/SubscriptionRights";
import { initFollowParam } from "./components/FollowParam";
import { initBillingCycle } from "./components/BillingCycle";
import { initBasket } from "./components/Basket";
import { initCookieConsent } from "./components/CookieConsent";
import { initIframeManager } from "./components/IframeManager";
import { initHomeProductTotalPrice } from "./components/HomeProductTotalPrice";
import { initSweetAlert2} from "./components/SweetAlert";
import { initModalAutoOpen} from "./components/Modal";
import { initLst} from "./components/Lst";

// Si aucun cookie contenant le nombre de licences existe
// ou s'il existe, mais que l'utilisateur n'est pas sur la page d'accueil
// alors, on initialise le cookie à 1.
// if(!Cookies.get('number_licences') || window.location.pathname !== '/') {
//     setNumberLicences(1)
// }

import Events from './dom/events'
const events = new Events('load', [
    initNumberLicences,
    initSubscriptionRights,
    initBillingCycle,
    initBasket,
    initFollowParam,
    initIframeManager,
    initCookieConsent,
    initHomeProductTotalPrice,
    initSweetAlert2,
    initModalAutoOpen,
    initLst,
    () => {
        document.dispatchEvent(new Event('number-licences-updated'))
    }
])

events.init()
