declare type SubscriptionRightsItem = {
    triggerEl: HTMLElement,
    valuesContainerEl: HTMLElement,
    targetEl: HTMLSelectElement,
    name: string,
}

/**
 * Met à jour l'affichage du prix des droits de souscription en fonction de la sélection
 * @param item
 * @version 0.0.1 - ##/##/#### by LM -
 *
 */
const updatePriceDisplay = (item: SubscriptionRightsItem): void => {
    const selectedIndex = item.targetEl.selectedIndex;

    // Afficher uniquement le conteneur de valeurs correspondant à l'index sélectionné
    item.valuesContainerEl.querySelectorAll('[data-index]').forEach((el) => {
        el.classList.add('hidden');
    });

    const selectedValueContainer = item.valuesContainerEl.querySelector(`[data-index="${selectedIndex}"]`);
    selectedValueContainer?.classList.remove('hidden');
};

/**
 * Synchronise les sélecteurs et les prix des droits de souscription
 * @param items
 * @param changedItem
 * @version 0.0.1 - ##/##/#### by LM -
 * @version 0.0.2 - 12/03/2024
 */
const synchronizeSelectsAndPrices = (items: SubscriptionRightsItem[], changedItem: SubscriptionRightsItem) => {
    items
        .filter(item => (item.targetEl !== changedItem.targetEl))
        .filter(item => item.name === changedItem.name)
        .forEach(item => {
            item.targetEl.value = changedItem.targetEl.value;
            updatePriceDisplay(item)
        });
};


/**
 * Initialise les gestionnaires d'évenements pour les droits de souscriptions
 * @version 0.0.1 - ##/##/#### by LM -
 * @version 0.0.2 - 11/03/2024 by LM - Déplacement de logique dans la structure HTML
 */
const initSubscriptionRightsHandlers = (items): void => {
    items.forEach(item => {
        if(item.targetEl) {
            item.targetEl.addEventListener('change', () => {
                updatePriceDisplay(item);
                synchronizeSelectsAndPrices(items, item);
                document.dispatchEvent(new CustomEvent('subscription-rights-updated', {
                    detail: {
                        item
                    }
                }));
            });
        }
    });
};

/**
 * Initialisation du module
 * @version 0.0.1 - ##/##/#### by LM -
 * @version 0.0.2 - 11/03/2024 by LM
 */
export const initSubscriptionRights = (): void => {
    const items: SubscriptionRightsItem[] = Array.from(document.querySelectorAll('[data-subscription-rights]')).map(el => ({
        triggerEl: el as HTMLElement,
        valuesContainerEl: el.querySelector('[data-subscription-rights-values]') as HTMLElement,
        targetEl: el.querySelector('[data-subscription-rights-selector]') as HTMLSelectElement,
        name: el.getAttribute('data-subscription-rights')!,
    }));

    initSubscriptionRightsHandlers(items);
};


