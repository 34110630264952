export const fr = {
    consentModal: {
        title: 'Cookies',
        description: 'Ce site utilise des cookies permettant de visualiser des contenus et d\'améliorer le ' +
            'fonctionnement grâce aux statistiques de navigation. Si vous cliquez sur « Accepter », PC SOFT ' +
            '(éditeur du site abonnement.pcsoft.fr) et ses partenaires déposeront ces cookies sur votre terminal lors ' +
            'de votre navigation. Si vous cliquez sur « Refuser », ces cookies ne seront pas déposés. ' +
            'Votre choix est conservé pendant 12 mois et vous pouvez être informé et modifier vos préférences ' +
            'à tout moment sur la page « Gérer les cookies ».',
        acceptAllBtn: 'Accepter',
        acceptNecessaryBtn: 'Refuser',
        showPreferencesBtn: 'Gérer les cookies'
    },
    preferencesModal: {
        title: 'Gestion des cookies',
        acceptAllBtn: 'Tout accepter',
        acceptNecessaryBtn: 'Tout refuser',
        savePreferencesBtn: 'Sauvegarder ma sélection',
        closeIconLabel: 'Fermer',
        sections: [
            {
                title: '',
                description: 'Un cookie est un fichier texte déposé sur votre ordinateur lors de votre visite ' +
                    'sur le site. Il permet de conserver vos données de navigation. Certaines fonctionnalités ' +
                    'listées ci-dessous s’appuient sur des services proposés par des tiers. Si vous donnez ' +
                    'votre accord (consentement), ces tiers déposeront des cookies qui permettront de ' +
                    'visualiser directement sur pcsoft.fr du contenu hébergé par ces tiers, de partager nos ' +
                    'contenus et d’améliorer le site grâce aux statistiques de navigation. Via ces cookies, ' +
                    'certains de ces tiers collecteront et utiliseront vos données de navigation pour des ' +
                    'finalités qui leur sont propres, conformément à leur politique de confidentialité. ' +
                    'Cette page vous permet de donner ou de retirer votre consentement à tout moment, ' +
                    'soit globalement soit finalité par finalité. Le site pcsoft.fr utilise également des ' +
                    'services de mesure d\'audience permettant de générer des statistiques anonymes utiles ' +
                    'à l\'amélioration du site que vous pouvez désactiver à tout moment sur cette page. ' +
                    'Pour en savoir plus, merci de lire notre <a href="https://pcsoft.fr/pcsoft/demande/protection-des-donnees.html" title="politique de confidentialité">politique de confidentialité</a>.'
            },
            {
                title: 'Cookies techniques nécessaires au bon fonctionnement du site',
                description: 'Ces cookies permettent au site de fonctionner de manière optimale. Vous pouvez ' +
                    'vous y opposer et les supprimer en utilisant les paramètres de votre navigateur, ' +
                    'cependant votre expérience utilisateur risque d’être dégradée.',

                //this field will generate a toggle linked to the 'necessary' category
                linkedCategory: 'necessary'
            },
            {
                title: 'Mesure d\'audience et d\'utilisation du site',
                description: 'Ils permettent d’obtenir des statistiques de fréquentation anonymes du site ' +
                    'afin d’optimiser son ergonomie, sa navigation et ses contenus.',
                linkedCategory: 'analytics'
            },
            {
                title: 'Streaming video',
                description: 'Ces cookies permettent la lecture de vidéos sur le site pcsoft.fr en provenance ' +
                    'de sites d’hébergement de contenus.',
                linkedCategory: 'streaming'
            }
        ]
    }
}
