import * as CookieConsent from "vanilla-cookieconsent";
import '@orestbida/iframemanager'
import '@orestbida/iframemanager/dist/iframemanager.css'
import { getCurrentLocaleByDomain} from "./CookieConsent";

export const initIframeManager = () : void => {
    // @ts-ignore
    const im = window.iframemanager()

    im.run({
        onChange: ({ changedServices, eventSource }) => {
            if (eventSource.type === 'click') {
                const servicesToAccept = [
                    ...CookieConsent.getUserPreferences().acceptedServices['streaming'],
                    ...changedServices,
                ];

                CookieConsent.acceptService(servicesToAccept, 'streaming');
            }
        },
        currLang: getCurrentLocaleByDomain(),
        services: {
            youtube: {
                embedUrl: 'https://www.youtube-nocookie.com/embed/{data-id}',
                thumbnailUrl: 'https://i3.ytimg.com/vi/{data-id}/hqdefault.jpg',

                iframe: {
                    allow:
                        'accelerometer; encrypted-media; gyroscope; picture-in-picture; fullscreen;',
                },

                cookie: {
                    name: 'cc_youtube',            // cookie name
                    path: '/',                     // cookie path          [OPTIONAL]
                    samesite: 'lax',               // cookie samesite      [OPTIONAL]
                    domain: location.hostname      // cookie domain        [OPTIONAL]
                },

                languages: {
                    fr: {
                        notice:
                            'Ce contenu est hébergé par un tiers. En affichant le contenu externe, vous acceptez les' +
                            ' <a rel="noreferrer noopener" href="https://www.youtube.com/t/terms" target="_blank">termes et conditions</a> ' +
                            'de youtube.com.',
                        loadAllBtn: 'Accepter',
                    },
                    en: {
                        notice:
                            'This content is hosted by a third party. By viewing external content, you agree to the ' +
                            '<a rel="noreferrer noopener" href="https://www.youtube.com/t/terms" target="_blank">terms and conditions</a> ' +
                            'of youtube.com.',
                        loadAllBtn: 'Accept',
                    },
                    es: {
                        notice:
                            'Este contenido es alojado por terceros. Al ver contenidos externos, usted acepta los ' +
                            '<a rel="noreferrer noopener" href="https://www.youtube.com/t/terms" target="_blank">términos y condiciones</a>' +
                            ' de youtube.com.',
                        loadAllBtn: 'Aceptar'
                    }
                },
            },
        },
    })
}

