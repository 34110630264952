import { forceBindRadioActive } from "./Lst";

/**
 * Met à jour la visibilité des éléments en fonction du cycle de facturation sélectionné.
 * @param billingCycleMode - Le mode de cycle de facturation sélectionné.
 */
const updateBillingCycleVisibility = (billingCycleMode: string): void => {
    document.querySelectorAll('[data-billing-cycle-mode]').forEach(modeItem => {
        // Si modeItem.getAttribute('data-billing-cycle-name existe et qu'il est égal à lst et que billingCycleMode est égal à 12M
        // Alors, on force l'affichage du le data-billing-cycle-mode 12A pour la LST
        if (modeItem.getAttribute('data-billing-cycle-name') === 'lst' && billingCycleMode === '12M') {
            modeItem.classList.toggle('hidden', modeItem.getAttribute('data-billing-cycle-mode') !== '12A');
        } else {
            modeItem.classList.toggle('hidden', modeItem.getAttribute('data-billing-cycle-mode') !== billingCycleMode);
        }

        document.querySelectorAll(`[data-billing-cycle-radio-mode="${billingCycleMode}"]`).forEach((radio: HTMLInputElement) => {
            radio.checked = true;
        });
    });

    forceBindRadioActive(billingCycleMode);
}

/**
 * Gère la mise à jour des classes pour tous les boutons de cycle de facturation en fonction de l'état actif/inactif du bouton cliqué.
 * @param activeSelector - Le sélecteur du mode de facturation actif.
 */
const updateBillingCycleButtonClasses = (activeSelector: string): void => {

    const buttons = document.querySelectorAll('[data-billing-cycle-mode-selector]');
    const buttonsArray = Array.from(buttons);
    const activeIndex = buttonsArray.findIndex(button => button.getAttribute('data-billing-cycle-mode-selector') === activeSelector);

    // Réinitialise tous les boutons à leur état par défaut et gère les ajustements des bordures
    buttons.forEach((button, index) => {
        const isDisabled = button.hasAttribute('disabled');
        if (!isDisabled) {
            // Remet les classes par défaut
            const defaultClasses = button.getAttribute('data-default-classes').split(' ');
            button.className = ''; // Nettoyage des classes
            defaultClasses.forEach(className => button.classList.add(className));

            // Reset les ajustements de bordure
            if (index === 1 || index === buttonsArray.length - 2) {
                button.classList.remove('border-l-0', 'border-r-0');
                // Réapplique les ajustements de bordure par défaut
                if (index === 1) button.classList.add('border-l');
                if (index === buttonsArray.length - 2) button.classList.add('border-r');
            }
        }
    });

    // Applique l'état actif sur le bouton qui a été cliqué
    const activeButton = buttonsArray[activeIndex];
    if (activeButton) {
        const activeClasses = activeButton.getAttribute('data-active-classes').split(' ');
        activeButton.className = ''; // Clear existing classes
        activeClasses.forEach(className => activeButton.classList.add(className));

        // Ajuste la bordure du bouton voisin si nécessaire
        if (activeIndex === 0 && buttonsArray.length > 1) {
            // Retire la bordure gauche du second bouton
            buttonsArray[1].classList.remove('border-l');
            buttonsArray[1].classList.add('border-l-0');
        } else if (activeIndex === buttonsArray.length - 1 && buttonsArray.length > 1) {
            // Retire la bordure droite de l'avant-dernier bouton
            buttonsArray[activeIndex - 1].classList.remove('border-r');
            buttonsArray[activeIndex - 1].classList.add('border-r-0');
        }
    }

    // document.dispatchEvent(new CustomEvent('billing-cycle-updated', {
    //     detail: {
    //         billingCycle: activeSelector
    //     }
    // }))
}

/**
 * Initialise les gestionnaires d'événements pour les boutons de cycle de facturation.
 */
const setupBillingCycleButtons = (): void => {
    document.querySelectorAll('[data-billing-cycle-mode-selector]').forEach(button => {
        button.addEventListener('click', (e) => {
            e.stopPropagation();
            const selector = button.getAttribute('data-billing-cycle-mode-selector');
            updateBillingCycleVisibility(selector);
            updateBillingCycleButtonClasses(selector);
        });
    });
}

/**
 * Initialise les boutons de cycle de facturation.
 */
export const initBillingCycle = (): void => {
    setupBillingCycleButtons();
}

