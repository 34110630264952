interface FollowParamOptions {
    onMergeSearchParams?: (currentURL: URL, targetURL: URL) => URL;
    onMergeHash?: (currentURL: URL, targetURL: URL) => URL;
}

/**
 * Paramètres d'url à ne pas faire suivre.
 */
const exceptFollowParams = [
    "subscription-rights-opened",
    "infocompte",
    "cart"
]

/**
 * Fusionne les paramètres de recherche de deux URLs.
 */
const mergeSearchParams = (
    currentURL: URL,
    targetURL: URL,
    onMerge: (currentURL: URL, targetURL: URL) => URL = (_, target) => target
): URL => {
    const mergedParams = new URLSearchParams(targetURL.searchParams);

    currentURL.searchParams.forEach((value, key) => {
        const newValue = (targetURL.host === "doc.pcsoft.fr" && value === "") ? "pcsnullparam" : value;
        if (exceptFollowParams.includes(key)) {
            return;
        }
        mergedParams.set(key, newValue);
    });

    targetURL.search = mergedParams.toString();
    return onMerge(currentURL, targetURL);
}

/**
 * Fusionne les hash des deux URLs.
 */
const mergeHash = (
    currentURL: URL,
    targetURL: URL,
    onMerge: (currentURL: URL, targetURL: URL) => URL = (_, target) => target
): URL => {
    if (!targetURL.hash) {
        targetURL.hash = currentURL.hash;
    }
    return onMerge(currentURL, targetURL);
}

/**
 * Initialise l'élément FollowParam en fusionnant son URL avec l'URL courante.
 */
export const initFollowParamElement = (link: HTMLAnchorElement, options: FollowParamOptions = {}): void => {
    const currentURL = new URL(window.location.href);
    const targetURL = new URL(link.href);

    const newTargetURL = mergeSearchParams(currentURL, targetURL, options.onMergeSearchParams);
    link.href = (mergeHash(currentURL, newTargetURL, options.onMergeHash)
        .toString())
        .replaceAll('=pcsnullparam', '');
}

/**
 * Initialise les fonctionnalités pour le suivi des paramètres des liens entre les pages.
 * avec le format de base des sites PC SOFT (utilise la classe .FollowParam).
 * Pour l'utiliser en passant des options spécifiques voir initFollowParamElement.
 */
export const initFollowParam = (): void => {
    document.querySelectorAll<HTMLAnchorElement>('.FollowParam').forEach(link => {
        link.addEventListener('mousedown', () => {
            initFollowParamElement(link);
        });
    });

    // Tous les liens qui commence par le même host du site courant
    // sont considérés comme des liens internes.
    document.querySelectorAll<HTMLAnchorElement>('a').forEach(link => {
        if (link.href.startsWith(window.location.origin)) {
            link.addEventListener('mousedown', () => {
                initFollowParamElement(link);
            });
        }
    });
}

// if (typeof window !== "undefined") {
//     window.initFollowParam = initFollowParam;
// }
