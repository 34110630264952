/*
 * Initialise l'ouverture automatique des modals si le paramètre show= est passé dans l'url
 */
export const initModalAutoOpen = () => {
    const url = new URL(window.location.href)
    if(url.searchParams.has('show')) {
        const openModalButton : HTMLElement = document.querySelector(`[data-modal-target="${url.searchParams.get('show')}"]`)
        if(openModalButton) {
            openModalButton.click()
        }
    }
}
