/**
 * Met à jour le prix total affiché pour un bloc de la page d'accueil
 * lors de la sélection d'un choix de souscription
 *
 * @version 0.0.1 - 13/03/2024 by LM -
 */
export const initHomeProductTotalPrice = () => {
    document.addEventListener('subscription-rights-updated', (e: CustomEvent) => {
        const detail = e.detail;
        const item = detail ? detail.item : null;
        if (!item) return;

        // Utilise 'closest' pour trouver un conteneur plus spécifique et réduire la fragilité liée à la structure du DOM
        const parent = item.targetEl.closest('[data-home-product]');
        if(!parent) return;

        const totalPriceContainer = parent.querySelector('[data-home-product-total-price]');
        if (!totalPriceContainer) return;

        const selectedIndex = item.targetEl.selectedIndex;

        // Cache tous les éléments sauf celui sélectionné
        const allValueContainers = totalPriceContainer.querySelectorAll('[data-index]');
        allValueContainers.forEach((el, index) => {
            el.classList.toggle('hidden', index !== selectedIndex);
        });
    });
}
