export const en = {
    consentModal: {
        title: 'Cookies',
        description: 'This site uses cookies to display content and to improve based on browsing statistics. If you click ' +
            '"Accept", PC SOFT (publisher of windev.com) and its partners will store these cookies on your device when ' +
            'you browse the site. If you click "Reject", these cookies will not be stored. Your settings are kept for ' +
            '12 months. You can view and change your preferences at any time on the "Manage cookies" page.',
        acceptAllBtn: 'Accept',
        acceptNecessaryBtn: 'Reject',
        showPreferencesBtn: 'Manage cookies'
    },
    preferencesModal: {
        title: 'Gestion des cookies',
        acceptAllBtn: 'Tout accepter',
        acceptNecessaryBtn: 'Tout refuser',
        savePreferencesBtn: 'Sauvegarder ma sélection',
        closeIconLabel: 'Close',
        sections: [
            {
                title: '',
                description: 'A cookie is a text file stored on your computer when you visit the site. It is used to store ' +
                    'your browsing data. Some of the features listed below rely on services provided by third parties. ' +
                    'If you agree (consent), these third parties will store cookies to display content directly on ' +
                    'windev.com, share our content and improve the site based on browsing statistics. Through these ' +
                    'cookies, some of these third parties will collect and use your browsing data for their own purposes, ' +
                    'in accordance with their privacy policy. This page allows you to give or withdraw your consent at ' +
                    'any time, either globally or purpose by purpose. windev.com also uses audience measurement services ' +
                    'to generate anonymous statistics that help us improve the site. This can be disabled at any time on' +
                    'this page. To learn more, please read our <a href="https://windev.com/pcsoft/data-protection-policy.htm" title="privacy policy">privacy policy</a>.'
            },
            {
                title: 'Technical cookies necessary for the functioning of the site',
                description: 'These cookies are used to optimize site functionality. You can reject and delete them via ' +
                    'your browser settings. However, user experience may be affected.',

                //this field will generate a toggle linked to the 'necessary' category
                linkedCategory: 'necessary'
            },
            {
                title: 'Audience measurement and site use',
                description: 'They allow us to obtain anonymous statistics on the number of visits to the site in order ' +
                    'to optimize the interface, navigation and content.',
                linkedCategory: 'analytics'
            },
            {
                title: 'Video streaming',
                description: 'These cookies allow playing videos from content hosting sites on windev.com.',
                linkedCategory: 'streaming'
            }
        ]
    }
}
