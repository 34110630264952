export const es = {
    consentModal: {
        title: 'Cookies',
        description: 'Este sitio utiliza cookies para mostrar contenido y mejorar en función de las estadísticas de navegación. Si hace clic en "Aceptar", PC SOFT (editor de we) sus socios externos almacenarán las cookies en su dispositivo cuando navegue por el sitio. Si hace clic en "Rechazar", estas cookies no se almacenarán. Su configuración se conserva durante 12 meses. Puede ver y cambiar sus preferencias en cualquier momento en la página "Administrar cookies".',
        acceptAllBtn: 'Aceptar',
        acceptNecessaryBtn: 'Rechazar',
        showPreferencesBtn: 'Administrar cookies'
    },
    preferencesModal: {
        title: 'Gestion des cookies',
        acceptAllBtn: 'Tout accepter',
        acceptNecessaryBtn: 'Tout refuser',
        savePreferencesBtn: 'Sauvegarder ma sélection',
        closeIconLabel: 'Fermer',
        sections: [
            {
                title: '',
                description: 'Una cookie es un archivo de texto que se almacena en su equipo cuando visita el sitio. Se utiliza ' +
                    'para almacenar sus datos de navegación. Algunas de las funcionalidades que aparecen a continuación dependen ' +
                    'de los servicios prestados por terceros. Si está de acuerdo, estos terceros almacenarán cookies para mostrar ' +
                    'contenido directamente en windev.es, compartir nuestro contenido y mejorar el sitio basándose en las ' +
                    'estadísticas de navegación. A través de estas cookies, algunos de los terceros recogerán y utilizarán los ' +
                    'datos de navegación para sus propios fines, de acuerdo con su política de privacidad. Esta página le permite ' +
                    'dar o retirar su consentimiento en cualquier momento, ya sea de forma global o por cada finalidad. ' +
                    'El sitio windev.es también utiliza servicios de medición de audiencia para generar estadísticas anónimas ' +
                    'que nos ayuden a mejorar el sitio. Esto se puede desactivar en cualquier momento en esta página.  ' +
                    'Para saber más, lea nuestra <a href="https://windev.es/pcsoft/politica-de-proteccion-de-datos.htm" title="política de privacidad">política de privacidad.</a>'
            },
            {
                title: 'Cookies técnicas necesarias para el funcionamiento del sitio',
                description: 'Estas cookies se utilizan para optimizar la funcionalidad del sitio. Puede rechazarlas y ' +
                    'eliminarlas en la configuración de su navegador. Sin embargo, esto puede afectar la experiencia del usuario.',

                //this field will generate a toggle linked to the 'necessary' category
                linkedCategory: 'necessary'
            },
            {
                title: 'Medición de audiencia y uso del sitio',
                description: 'Permiten obtener estadísticas anónimas sobre el número de visitas para optimizar la ' +
                    'interfaz, navegación y contenido del sitio.',
                linkedCategory: 'analytics'
            },
            {
                title: 'Transmisión de video',
                description: 'Estas cookies permiten reproducir videos que provienen de sitios de alojamiento de ' +
                    'contenido en windev.es.',
                linkedCategory: 'streaming'
            }
        ]
    }
}
