// Fonction utilitaire pour basculer la classe 'hidden'
const toggleVisibility = (elements, shouldHide) => {
    elements.forEach(element => element.classList.toggle('hidden', shouldHide));
};

// Fonction pour basculer la visibilité des listes de prix
const togglePriceList = (checked) => {
    const pricesLst = document.querySelectorAll('[data-prices-lst]');
    const pricesLstEx = document.querySelectorAll('[data-prices-lst-ex]');

    toggleVisibility(pricesLst, checked);
    toggleVisibility(pricesLstEx, !checked);

    bindRadioPrice();
};

// Fonction pour réappliquer la sélection des radios de la liste précédemment active à la nouvelle liste active
const bindRadioPrice = () => {
    const oldActiveList = document.querySelectorAll('[data-prices-lst-modal].hidden input[type="radio"]:checked');
    const newActiveList = document.querySelectorAll('[data-prices-lst-modal]:not(.hidden) input[type="radio"]');

    oldActiveList.forEach(checkedInput => {
        const inputCycle = checkedInput.getAttribute('data-billing-cycle-radio-mode');
        if (inputCycle) {
            const correspondingInput = Array.from(newActiveList).find(input => input.getAttribute('data-billing-cycle-radio-mode') === inputCycle);
            if (correspondingInput) {
                correspondingInput.checked = true;
            }
        }
    });
};

// Force la sélection des radios actifs selon le mode de cycle de facturation
export const forceBindRadioActive = (billingCycleMode) => {
    const newActiveList = document.querySelectorAll(`[data-prices-lst-modal]:not(.hidden) [data-billing-cycle-radio-mode="${billingCycleMode}"]`);
    newActiveList.forEach(input => input.checked = true);
};

// Initialisation des interrupteurs LST
export const initLst = () => {
    const switchInputs = document.querySelectorAll('[data-lstex-switcher]');

    switchInputs.forEach(input => {
        input.addEventListener('change', (e) => {
            const checked = e.target.checked;
            switchInputs.forEach(switchInput => switchInput.checked = checked);
            togglePriceList(checked);
        });
    });

    if (switchInputs.length > 0) {
        togglePriceList(switchInputs[0].checked);
    }
};
