/**
 * Retourne la locale à utiliser pour afficher les montants
 * @version 0.0.1 - 17/04/2024 by LM -
 */
const getLocale = () => {
    return document.querySelector('html').getAttribute('data-currency-lang')
        ?.replace('_', '_')
        || 'en-US'
}

/**
 * Retourne la devise à utiliser pour afficher les montants
 * @version 0.0.1 - 17/04/2024 by LM -
 */
const getCurrency = () => {
    return document.querySelector('html').getAttribute('data-currency') || 'EUR'
}

/**
 * Affiche le tarif du droit de souscription
 * @param price
 * @param maximumFractionDigits Nombre de chiffres après la virgule
 */
export const formatPrice = (price: number, maximumFractionDigits: number = 2) => {
    // Formate le nombre, en français on met le signe de la devise à la fin
    return new Intl.NumberFormat(getLocale(), {
        style: 'currency',
        currency: getCurrency(),
        maximumFractionDigits: maximumFractionDigits,
    });
};
