import "vanilla-cookieconsent/dist/cookieconsent.css";
// @ts-ignore
import * as VanillaCookieConsent from "vanilla-cookieconsent";
import { fr, en, es } from "./../config/cookie-consent/lang/locales"

/**
 * Chemins qui exclu le bandeau de consentement des cookies
 * @type {string[]}
 */
const excludesPaths : string[] = [

]

/**
 * URLs qui exclu le bandeau de consentement des cookies
 * @type {string[]}
 */
const excludesQueryStrings : string[] = [

]

/**
 * Retourne la langue courante à utiliser en fonction du nom de domaine
 * @returns {string}
 */
export const getCurrentLocaleByDomain = () : string => {
    const url = new URL(window.location.href)

    switch (url.hostname) {
        case 'pcsoft.fr':
        case 'doc.pcsoft.fr':
            return 'fr'
        case 'windev.com':
            return 'en'
        case 'windev.es':
            return 'es'
        default:
            return 'fr'
    }
}


/**
 * Retourne un boolean indiquant s'il faut ou non déclencher le système en fonction de la page
 * @returns {boolean}
 */
const isExcluded = () : number|true => {
    const url = new URL(window.location.href)

    // Si le chemin courant de la page est dans les paramètres des chemins exclus
    // Ou si un paramètre contenu dans l'url est dans les paramètres exclus
    // Alors on retourne vrai pour ne pas déclencher le bandeau des cookies
    return !!(excludesPaths.includes(url.pathname))
        || excludesQueryStrings.filter(queryParam => url.searchParams.has(queryParam)).length
}

/**
 * Initialise le bandeau de consentement des cookies
 */
export const initCookieConsent = () : void => {
    if(!isExcluded()) {
        VanillaCookieConsent.run({
            guiOptions: {
                consentModal: {
                    layout: 'cloud',
                    position: 'bottom left',
                    flipButtons: false,
                    equalWeightButtons: true
                }
            },
            onConsent: function () {
                // Consent was given
            },
            onChange: function () {
                // user changed his/her preferences
            },
            categories: {
                necessary: {
                    enabled: true,  // this category is enabled by default
                    readOnly: true  // this category cannot be disabled
                },
                analytics: {
                    enabled: false,
                    readOnly: false,

                    // Delete specific cookies when the user opts-out of this category
                    autoClear: {
                        cookies: [
                            {name: /^_ga/ },   // regex: match all cookies starting with '_ga' Google
                            { name: /^__ut/ }, // regex: match all cookies starting with '__ut' Google
                            { name: '_gid'},   // string: exact cookie name Google
                            { name: 't_gid'}, // Taboola
                            { name: 'auth_token' }, // Twitter
                            { name: 'dnt' }, // Twitter
                            { name: 'guest_id' }, // Twitter
                            { name: 'personalization_id' }, // Twitter
                            { name: 'personalization_id', path: '/', domain: 'twitter.com' }, // Twitter
                            { name: 'personalization_id', path: '/', domain: 'www.twitter.com' }, // Twitter
                        ]
                    }
                },
                streaming: {
                    enabled: false,
                    readOnly: false,

                    // Delete specific cookies when the user opts-out of this category
                    autoClear: {
                        cookies: [
                            { name: 'VISITOR_INFO1_LIVE', path: '/', domain: '.youtube.com' },
                            { name: 'VISITOR_INFO1_LIVE', path: '/', domain: 'youtube.com' },
                            { name: 'VISITOR_INFO1_LIVE', path: '/', domain: 'www.youtube.com' },
                            { name: 'YSC', path: '/', domain: '.youtube.com' },
                            { name: 'YSC', path: '/', domain: 'youtube.com' },
                            { name: 'YSC', path: '/', domain: 'www.youtube.com' }
                        ]
                    },

                    // Serivce youtube lié à l'intégration des cookies streaming
                    services: {
                        youtube: {
                            label: 'Intégration Youtube',
                            onAccept: () => {
                                // @ts-ignore
                                window.iframemanager().acceptService('youtube')
                            },
                            onReject: () => {
                                // @ts-ignore
                                window.iframemanager().rejectService('youtube')
                            }
                        },
                    },
                }
            },

            // Configuration de la langue
            language: {
                default: getCurrentLocaleByDomain(),
                translations: {
                    fr: fr,
                    en: en,
                    es: es
                }
            }
        })
    }
}
